import axios from 'axios';
import constant from './constant';
const logincheckUrl = "logincheck"
const registerUrl = "register"
const resendotpUrl = "resendotp"
const completeprofileUrl = "completeprofile"; 
const userdataUrl = "getuserdata"; 
const stateDataUrl = "getStates";  
const cityDataUrl = "getCity"; 

const checkPincodeUrl = "check-pincode"; 

const getCityDataUrl = "getCityData" ;
const getBanksUrl = "getBanks";  
const getMICRUrl = "https://ifsc.razorpay.com/";
const saveuserbankdataUrl = "saveuserbankdata";
const getinvestorconcentUrl = "getinvestorconcent";
const resetpasswordUrl = "bse/resetpassword";
const bsecompleteprofileUrl = "bse/completeprofile";
const bseregisterUrl = "bse/register";
const bseresendotpUrl = "bse/resendotp";
const bsegetuserdataUrl = "bse/getuserdata";
const bselogincheckUrl = "bse/logincheck"
const bseuserregisterUrl = "bse/bseregistrationstep1"
const bsebankdetailUrl = "bse/bseregistrationstep2"
const bsecountrydataUrl = "get-country"; 
const bsestatedataUrl = "get-states-data"; 
const bseuseraddressUrl = "bse/bseregistrationstep3"
const bseuseraddresscontUrl = "bse/bseregistrationstep3cont"
const bseusernomineeUrl = "bse/bseregistrationstep4"
const bsebankdataUrl = "get-banks-data"; 
const ifscdataUrl = "getifsc"; 
const bsenriuserUrl = "bse/bseregistrationforeign"
const bseonboardingotpUrl = "sendbseonboardotp"
const verifybseonboardotpUrl = "verifybseonboardotp"
const bseregistrationminorUrl = "bse/bseregistrationminor"
const bseregistrationkycdetailsUrl = "bse/bseregistrationkycdetails"
const clientregitrationUrl = "clientregitration"
const bseaufsignatureuploadUrl = "bse/bseaufsignatureupload"
// const bseaufsignatureURL="bseaufsignatureupload"
const getBseFirstStepDataUrl = "bse/getBseFirstStepData"
const getuserbankdataUrl = "bse/getuserbankdata"
const bseaddbankUrl = "bse/bseaddbank"
const deletebseaddbank="bse/deletebsebankaccount"
const deletebsebankaccountfromDB="bse/deletebsebankaccountfromDB"
const makeprimarydetail="bse/markasprimarybankaccount"
const bseaufscreenshotuploadUrl = "bse/bseaufscreenshotupload"
const bsemandateregistrationrequestUrl = "bse/bsemandateregistrationrequest"
const getbsebankdetaildataUrl = "bse/getbanksdetaildata"
const resetpasswordbymailUrl = "bse/resetpasswordbymail"
const getpopularmutualfundsUrl ="bsemf/getpopularmutualfunds"

const popularbasketdetailsUrl ="popular-basket-details"

const getmutualfundslistUrl = "bsemf/getmutualfundslist"
const getquestiondataUrl="getquestionsdata"
const getsavequestiondataUrl="savequestiondata"
const getprevquesdataUrl="getprevquestiondata"
const getsaveriskUrl="saveuserriskscore"
const getsearchcontentUrl="bsemf/getmutualfundssearchlist"
const getsubmitgoalsettingURL="savegoalsettingsdata"
const themeGetRequest="gettheme";
const getmutualfundsdetailUrl = "bsemf/getmutualfundsdetail"
const getbsemutualfundamcUrl = "bsemf/getbsemutualfundamc"
const getfiltermutualfunddataUrl = "bsemf/getfiltermutualfunddata?page="
const getfiltercategoryUrl = "bsemf/getfiltercategory"
const showusergraphdataUrl="showusergraphdata"
const getgoalgraphdata = "getgoalgraphdata";
const getpreviousholdingsdataUrl ="getpreviousholdingsdata"
const getgoalstoxholdingsdataUrl ="getgoalstoxholdingsdata"
const createbseXSIPorderUrl = "order/createbseXSIPorder"
const sendotptransactionUrl = "bse/sendotptransaction"
const verifyotptransactionUrl = "bse/verifyotptransaction"
const sendloginotpUrl="bse/loginwithotp"
const verifyloginotpUrl="bse/matchloginotp"
const getuserdatawithoutraworignalUrl = "bse/getuserdatawithoutraworignal"
const getIncomesourceUrl="bse/getincomesource"
const getWealthsourceUrl="bse/getsourceofwealthdata"
const getAddressTypeUrl="bse/getaddresstype"
const getBseuFactcaUrl="bse/fatcaupdaterequset"
const getmutualInvestmentDataUrl = "bsemf/getmutualInvestmentData"
const getuserorderrequesturl="order/getuserorders"
const getuserorderdetailrequesturl="order/getuserorderdetail"
const getMysipsdetailrequesturl=""
const getuserordertransactionrequesturl="order/transactiondetail"
const gettransactionlistUrl="order/transactionlist"
const createbsePURCHASEorderUrl="order/createbsePURCHASEorder"
const getmultiplefundsiprequesturl="bsemf/getmultiplemutualfundsdata"
const bsecancelsipUrl = "order/bsecancelsip"
const gettotalgoalstoxportfolioUrl = "bse/gettotalgoalstoxportfolio"
const savegoaldataUrl = "bse/savegoaldata"
const getredeemfoliosUrl = "bse/getredeemfolios"
const redeemfundDataUrl = "bse/redeemfundData"
const getmutualFundSwitchDataUrl = "bse/getmutualFundSwitchData"
const createbseSwitchorderUrl = "bse/createbseSwitchorder"
const getSchemeNameDataByPortfolioUrl = "bse/getSchemeNameDataByPortfolio"
const createbseRedeemorderUrl = "bse/createbseRedeemorder"
const userBankDataUrl = "bse/userBankData";
const getmandatestatusUrl = "order/getmandatestatus"
const dashboradgraohdata = "bse/dashboradgraohdata"
const getgoalstoxholdingsdetaildataUrl = "bse/getgoalstoxholdingsdetaildata"
const getusersipdataUrl = "bse/getusersipdata"
const verifybseonboardnomineeauthotpUrl = "bse/verifybseonboardnomineeauthotp"
const resendotpnomineeauthUrl = "bse/resendotpnomineeauth"
const usernomineedataUrl = "bse/usernomineedata"
const paymentmethodUrl = "order/paymentmethod"
const popularbasketlistingUrl = "popularbasket/popularbasketlisting"
const popularbasketfeaturedlistingUrl = "popularbasket/popularbasketfeaturedlisting"
const getdistributedpopularbasketUrl = "popularbasket/getdistributedpopularbasket"
const createmultiplebsePURCHASEorderUrl = "popularbasket/createmultiplebsePURCHASEorder"
const createbseXSIPorderpopularbasketUrl = "popularbasket/createbseXSIPorderpopularbasket"
const getmultipletransactiondetailUrl = "popularbasket/getmultipletransactiondetail"
const sendotpredeemtransactionUrl = "bse/sendotpredeemtransaction"
const verifyotpredeemtransactionUrl = "bse/verifyotpredeemtransaction"
const resendredeemotpUrl = "bse/resendredeemotp"
const sendotpbankUrl = "bse/sendotpbank"
const verifyotpbankUrl = "bse/verifyotpbank"
const resendotpbankUrl = "bse/resendotpbank"
const mfuaccountcheckUrl = "bse/mfuaccountcheck"
const kycLoginUrl = "kyc/kycLogin"
const pullonboardingUrl = "kyc/pullonboarding"
const checkuserekycUrl = "checkuserekyc"
const directloginbyadminUrl = "bse/directloginbyadmin"
const getgoalstoxholdingsreportUrl = "bse/getgoalstoxholdingsreport"
const getgoalstoxholdingvaluationsreportUrl = "bse/getgoalstoxholdingvaluationsreport"
const getgoalstoxnewholdingsdataUrl = "getgoalstoxnewholdingsdata"
const createmandateauthlinkUrl = "bse/createmandateauthlink"

const paymentcallbackurlUrl='order/getpaymentstatus'
const nomineerelationshipUrl='bse/nomineerelationship'
const checkbankenachstatusUrl = 'bse/checkbankenachstatus'
const getuserbankdetailUrl = 'bse/getuserbankdetail'
const childorderUrl = 'order/childorder'


const client = axios.create({
    baseURL: constant.API_URL,
  });
  const client2 =axios.create({
    baseURL: constant.API_URL2,
  })

  function getauthtoken(){
    let token = localStorage.getItem('AUTH_TOKEN');
    let Authtoken = '';
    if(token !=null && token !='' && token !=undefined){
        Authtoken = token;
    }
    const config = {
      headers: { 'X-Authorization': `Bearer ${Authtoken}` }
    };
    return config;
    }

  export class ApiService {
    logincheckPostRequest(request){
        return client.post(logincheckUrl,request)
        }
        
        registerPostRequest(request){
        return client.post(registerUrl,request)
      
        }
        resendotpPostRequest(request){
        return client.post(resendotpUrl,request)
      
        }
        completeprofilePostRequest(request , ){
          return client.post(completeprofileUrl,request , getauthtoken() );
        }
        getUserDataPostRequest(request){
          return client.post(userdataUrl,request , getauthtoken());
        }
        getstatesGetRequest(){
          return client.get(stateDataUrl);
        }
        getcities(request){
          return client.post(cityDataUrl,request);
        }
        
        checkPincodePostRequest(request){
          return client.post(checkPincodeUrl,request , getauthtoken());
        }


        getCityDataGetRequest(){
          return client.get(getCityDataUrl);
        }
        getBanksGetRequest(){
          return client.get(getBanksUrl , getauthtoken());
        }
        getMICRGetRequest(IFSC){
          return client.get(getMICRUrl+IFSC);
        }
        saveuserbankdataPostRequest(request) {
          return client.post(saveuserbankdataUrl,request , getauthtoken());
        }
        getinvestorconcentPostRequest(request){
          return client.post(getinvestorconcentUrl,request,getauthtoken());
        }
        resetpasswordPostRequest(request){
          return client.post(resetpasswordUrl,request,getauthtoken());
        }
        bsecompleteprofilePostRequest(request){
          return client.post(bsecompleteprofileUrl,request,getauthtoken());
        }
        bseregisterPostRequest(request){
          return client.post(bseregisterUrl,request,getauthtoken());
        }
        bseresendotpPostRequest(request){
          return client.post(bseresendotpUrl,request,getauthtoken());
        }
        bsegetuserdataPostRequest(request){
          return client.post(bsegetuserdataUrl,request,getauthtoken());
        }
        bselogincheckPostRequest(request){
          return client.post(bselogincheckUrl,request,getauthtoken());
        }
        bseuserregisterUrlPostRequest(request){
          return client.post(bseuserregisterUrl,request,getauthtoken());
        }
        bsebankdetailUrlPostRequest(request){
          return client.post(bsebankdetailUrl,request,getauthtoken());
        }
        getbsecountryGetRequest(){
          return client.get(bsecountrydataUrl);
        }
        getbsestatesGetRequest(){
          return client.get(bsestatedataUrl);
        }
        bseuseraddressPostRequest(request){
          return client.post(bseuseraddressUrl,request,getauthtoken());
        }
        bseusernomineePostRequest(request){
          return client.post(bseusernomineeUrl,request,getauthtoken());
        }
        getbsebanksGetRequest(){
          return client.get(bsebankdataUrl);
        }
        ifscdataPostRequest(request){
          return client.post(ifscdataUrl,request)
          }
        bsenriuserPostRequest(request){
            return client.post(bsenriuserUrl,request,getauthtoken());
          }
      bseendonboardingotpUrlPostRequest(request){
        return client.post(bseonboardingotpUrl,request,getauthtoken());
      }
      verifybseonboardotpPostRequest(request){
        return client.post(verifybseonboardotpUrl,request,getauthtoken());
      }
      bseuseraddresscontPostRequest(request){
        return client.post(bseuseraddresscontUrl,request,getauthtoken());
      }
      bseregistrationminorPostRequest(request){
        return client.post(bseregistrationminorUrl,request,getauthtoken());
      }
      bseregistrationkycdetailsPostRequest(request){
        return client.post(bseregistrationkycdetailsUrl,request,getauthtoken());
      }
      clientregitrationPostRequest(request){
        return client.post(clientregitrationUrl,request,getauthtoken());
      }
      bseaufsignatureuploadPostRequest(request){
        return client.post(bseaufsignatureuploadUrl,request,getauthtoken());
      }
      getBseFirstStepDataGetRequest(){
        return client.get(getBseFirstStepDataUrl);
      }
      getuserbankdataPostRequest(request){
        return client.post(getuserbankdataUrl,request,getauthtoken());
      }
      getpopularmutualfunds(){
        return client.get(getpopularmutualfundsUrl,getauthtoken())
      }
      bseaddbankPostRequest(request){
        return client.post(bseaddbankUrl,request,getauthtoken());
      }
     
      bseaufscreenshotuploadPostRequest(request){
        return client.post(bseaufscreenshotuploadUrl,request,getauthtoken());
      }
      bsemandateregistrationrequestPostRequest(request){
        return client.post(bsemandateregistrationrequestUrl,request,getauthtoken());
      }
      getbsebankdetaildataPostRequest(request){
        return client.post(getbsebankdetaildataUrl,request,getauthtoken());
      }
      resetpasswordbymailPostRequest(request ){
        return client.post(resetpasswordbymailUrl,request);
      }
      deletebankaccount(request){
        return client.post(deletebseaddbank,request,getauthtoken())
      }
      deletebsebankaccountfromDB(request){
        return client.post(deletebsebankaccountfromDB,request,getauthtoken())
      }
      makebankprimary(request){
        return client.post(makeprimarydetail,request,getauthtoken())
      }
      getmutualfundslist(){
        return client.get(getmutualfundslistUrl,getauthtoken())
      }
      getquestiondata(){
        return client.get(getquestiondataUrl,getauthtoken())
      }
      saveQuestionData(request){
        return client.post(getsavequestiondataUrl,request,getauthtoken())
      }
      saveriskscore(request){
        return client.post(getsaveriskUrl,request,getauthtoken())
      }
      getprevquestiondata(request){
        return client.post(getprevquesdataUrl,request,getauthtoken())
      }
      submitgoalsettingsdata(request){
        return client.post(getsubmitgoalsettingURL,request,getauthtoken())
      } 
      getsignatureuploadrequest(request){
        return client.post(bseaufsignatureuploadUrl,request,getauthtoken())
      }
      getsearchcontentpostrequest(request){
        return client.post(getsearchcontentUrl,request,getauthtoken())
      }
      themeGetRequest(){
        return client.get(themeGetRequest,getauthtoken())
      }
      getmutualfundsdetailpostrequest(request){
        return client.post(getmutualfundsdetailUrl,request,getauthtoken())
      }
      // getbseuformgpostrequest(request){
      //   return client.post(bseuformregistrationform,request,getauthtoken())
      // }
      getfiltermutualfunddatapostrequest(request,pageNumber){
        return client.post((getfiltermutualfunddataUrl+pageNumber),request,getauthtoken())
      }
      getbsemutualfundamcGetRequest(){
        return client.get(getbsemutualfundamcUrl,getauthtoken())
      }
      getfiltercategorydatapostrequest(){
        return client.get(getfiltercategoryUrl,getauthtoken())
      }
      getusergraphdata(request){
        return client.post(showusergraphdataUrl,request,getauthtoken())
      }
      getgoalgraphdata(request){
        return client.post(getgoalgraphdata,request,getauthtoken())
      }
      getgoalstoxholdingsdataPostRequest(request){
        return client.post(getgoalstoxholdingsdataUrl,request,getauthtoken());
      }
      getgoalstoxprevholdingsdataPostRequest(request){
        return client.post(getpreviousholdingsdataUrl,request,getauthtoken());
      }
      createbseXSIPorderPostRequest(request){
        return client.post(createbseXSIPorderUrl,request,getauthtoken());
      }
      sendotptransactionPostRequest(request){
        return client.post(sendotptransactionUrl,request,getauthtoken());
      }
      verifyotptransactionPostRequest(request){
        return client.post(verifyotptransactionUrl,request,getauthtoken());
      }
      sendloginotpPostRequest(request){
        return client.post(sendloginotpUrl,request,getauthtoken());
      }
      verifyloginotpPostRequest(request){
        return client.post(verifyloginotpUrl,request,getauthtoken());
      }
      getuserdatawithoutraworignalPostRequest(request){
        return client.post(getuserdatawithoutraworignalUrl,request,getauthtoken());
      }
      getIncomesourcegetrequest(){
        return client.get(getIncomesourceUrl);
      }
      getwealthsourcegetrequest(){
        return client.get(getWealthsourceUrl);
      }
      getAddressTypegetrequest(){
        return client.get(getAddressTypeUrl);
      }
      getFatcaapirequest(request){
        return client.post(getBseuFactcaUrl,request,getauthtoken());
      }
      getmutualInvestmentDatarequest(request){
        return client.post(getmutualInvestmentDataUrl,request,getauthtoken());
      }
      getuserOrdergetrequest(pageNumber){
        return client.get((getuserorderrequesturl+"?page="+pageNumber),getauthtoken());
      }
      getuserOrderdetailgetrequest(request){
        return client.post(getuserorderdetailrequesturl,request,getauthtoken());
      }
      getuserOrdertransactiongetrequest(request){
        return client.post(getuserordertransactionrequesturl,request,getauthtoken());
      }
      getOrdertransactionlistgetrequest(request){
        return client.post(gettransactionlistUrl,request,getauthtoken());
      }
      getMySipsdetailgetrequest(request){
        return client.post(getMysipsdetailrequesturl,request,getauthtoken());
      }
      getmultiplefundsipostrequest(request){
        return client.post(getmultiplefundsiprequesturl,request,getauthtoken());
      }
      createbsePURCHASEorderpostrequest(request){
        return client.post(createbsePURCHASEorderUrl,request,getauthtoken());
      }
      bsecancelsippostrequest(request){
        return client.post(bsecancelsipUrl,request,getauthtoken());
      }     
      gettotalgoalstoxportfoliopostrequest(request){
        return client.post(gettotalgoalstoxportfolioUrl,request,getauthtoken());
      }     
      savegoaldatapostrequest(request){
        return client.post(savegoaldataUrl,request,getauthtoken());
      }     
      getredeemfoliospostrequest(request){
        return client.post(getredeemfoliosUrl,request,getauthtoken());
      }     
      redeemfundDatapostrequest(request){
        return client.post(redeemfundDataUrl,request,getauthtoken());
      }     
      getmutualFundSwitchDatapostrequest(request){
        return client.post(getmutualFundSwitchDataUrl,request,getauthtoken());
      }     
      createbseSwitchorderpostrequest(request){
        return client.post(createbseSwitchorderUrl,request,getauthtoken());
      }     
      getSchemeNameDataByPortfoliopostrequest(request){
        return client.post(getSchemeNameDataByPortfolioUrl,request,getauthtoken());
      }     
      createbseRedeemorderpostrequest(request){
        return client.post(createbseRedeemorderUrl,request,getauthtoken());
      }     
      userBankDatapostrequest(request){
        return client.post(userBankDataUrl,request,getauthtoken());
      }     
      getmandatestatuspostrequest(request){
        return client.post(getmandatestatusUrl,request,getauthtoken());
      }     
      dashboradgraohdatapostrequest(request){
        return client.post(dashboradgraohdata,request,getauthtoken());
      }     
      getgoalstoxholdingsdetaildatapostrequest(request){
        return client.post(getgoalstoxholdingsdetaildataUrl,request,getauthtoken());
      }     
      getusersipdatapostrequest(request){
        return client.post(getusersipdataUrl,request,getauthtoken());
      }     
      verifybseonboardnomineeauthotppostrequest(request){
        return client.post(verifybseonboardnomineeauthotpUrl,request,getauthtoken());
      }     
      resendotpnomineeauthpostrequest(request){
        return client.post(resendotpnomineeauthUrl,request,getauthtoken());
      }     
      paymentmethodpostrequest(request){
        return client.post(paymentmethodUrl,request,getauthtoken());
      }     
      usernomineedatapostrequest(request){
        return client.post(usernomineedataUrl,request,getauthtoken());
      }     
      popularbasketlistingpostrequest(){
        return client.get(popularbasketlistingUrl,getauthtoken());
      }     
      popularbasketfeaturedlistingpostrequest(){
        return client.get(popularbasketfeaturedlistingUrl,getauthtoken());
      } 
      popularbasketdetailsurlpostrequest(request){
        return client.post(popularbasketdetailsUrl,request,getauthtoken());
      }     
      getdistributedpopularbasketpostrequest(request){
        return client.post(getdistributedpopularbasketUrl,request,getauthtoken());
      }     
      createmultiplebsePURCHASEorderpostrequest(request){
        return client.post(createmultiplebsePURCHASEorderUrl,request,getauthtoken());
      }     
      createbseXSIPorderpopularbasketpostrequest(request){
        return client.post(createbseXSIPorderpopularbasketUrl,request,getauthtoken());
      }     
      getmultipletransactiondetailpostrequest(request){
        return client.post(getmultipletransactiondetailUrl,request,getauthtoken());
      }     
      
      sendotpredeemtransactionpostrequest(request){
        return client.post(sendotpredeemtransactionUrl,request,getauthtoken());
      }     
      verifyotpredeemtransactionpostrequest(request){
        return client.post(verifyotpredeemtransactionUrl,request,getauthtoken());
      }     
      resendredeemotppostrequest(request){
        return client.post(resendredeemotpUrl,request,getauthtoken());
      }     
      kycLoginpostrequest(request){
        return client.post(kycLoginUrl,request,getauthtoken());
      }     
      
      sendotpbankpostrequest(request){
        return client.post(sendotpbankUrl,request,getauthtoken());
      }     
      
      verifyotpbankpostrequest(request){
        return client.post(verifyotpbankUrl,request,getauthtoken());
      }     
      resendotpbankpostrequest(request){
        return client.post(resendotpbankUrl,request,getauthtoken());
      }     
      
      mfuaccountcheckpostrequest(request){
        return client.post(mfuaccountcheckUrl,request,getauthtoken());
      }     
      checkuserekycpostrequest(request){
        return client.post(checkuserekycUrl,request,getauthtoken());
      }     
      
      pullonboardingpostrequest(request){
        return client.post(pullonboardingUrl,request,getauthtoken());
      }     
      directloginbyadminpostrequest(request,headers){
        return client.post(directloginbyadminUrl,request,{ headers });
      }     
      getgoalstoxnewholdingsdatapostrequest(request,headers){
        return client.post(getgoalstoxnewholdingsdataUrl,request,getauthtoken());
      }     
     getgoalstoxholdingsreportpostrequest(request){
        return client.post(getgoalstoxholdingsreportUrl,request,getauthtoken());
      }     
      getgoalstoxholdingvaluationsreportpostrequest(request){
        return client.post(getgoalstoxholdingvaluationsreportUrl,request,getauthtoken());
      }     
      paymentcallbackurlpostrequest(request){
        return client.post(paymentcallbackurlUrl,request,getauthtoken());
      }    
      createmandateauthlinkpostrequest(request){
        return client.post(createmandateauthlinkUrl,request,getauthtoken());
      }  

      
      nomineerelationshipgetrequest(request){
        return client.get(nomineerelationshipUrl)
      }
      checkbankenachstatuspostrequest(request){
        return client.post(checkbankenachstatusUrl,request,getauthtoken());
      }  
      getuserbankdetailpostrequest(request){
        return client.post(getuserbankdetailUrl,request,getauthtoken());
      }  
      childorderpostrequest(request){
        return client.post(childorderUrl,request,getauthtoken());
      }  

      
      
  }